var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
var TweenLite = require('gsap');
var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');

var PhotoSwipe = require('./photoswipe/photoswipe.min.js');
var PhotoSwipeUI_Default = require('./photoswipe/photoswipe-ui-default.min.js');

var Parsley = require('parsleyjs');
var Hammer = require('hammerjs');
var LazyLoad = require('lazyload');

// var slidesArray = require('../assets/slides.json');

window.app = {
	W: $(window).width(),
	H: $(window).height(),
	scrollTop: $(window).scrollTop(),
	offerInterval: null,
	offerIndex: 1,
	isMobileNavOpen: false,
	isHome: $('.page-template-page-home').length > 0,

	sliderInstance: {},

	init: function() {
		// this.checkMenuItems();

		var _this = this;

		_this.setupEvents();
		_this.setNavURL();
		_this.preload([]);
		_this.initLazyLoad();
		_this.setOfferHeight();
		_this.startOfferInterval();
	},

	setupEvents: function() {
		var _this = this;

		$('.page-offer-gallery img').click(function() {
			var index = $(this).data('index');
			
			_this.initPhotoswipe(index);
		})

		$('form').on('click', '.button', function(event) {
			event.preventDefault();
			$(this).submit();
		});

		$('form').on('submit', function(event) {
			event.preventDefault();

			var $this = $(this),
				action = $(this).attr('action'),
				data = $(this).serialize(),
				formId = $this.attr('id');

		    $.ajax({
		      url : action,
		      type : 'POST',
		      cache : false,
		      data : data,
		      success : function(res) {

		        res = JSON.parse(res)

		        if (res.status == false) {
		        	$this.find('.error').removeClass('hidden');

		        	if ($this.attr('id') == 'login') {
		        		$this.find('input').val('')
		        	}

		        	if ($this.attr('id') == 'request') {
		        		
		        	}
		        }

		        if (res.status == true && formId == 'login') {
		        	window.location.reload(true);
		        }

		        if (res.status == true && formId == 'request') {
		        	$this.find('.error').addClass('hidden');
		        	$this.find('.success').removeClass('hidden');

		        	setTimeout(function() {
		        		window.location = '/';
		        	}, 1600)
		        }
		      }
		    })
			
		});

		$('.slider-nav').on('click', 'a', function(event) {
			event.preventDefault();
			var elementIndex = $(this).parent().index();

			_this.sliderInstance.move(elementIndex);
		});

		$('.header-mobileNav-trigger a').click(function(event) {
			event.preventDefault();
			_this.showHideMobileNav();
		});

		$('a[href*="#"]').not('[href="#"]').not('[href="#0"]') .click(function(event) {
			if (_this.isHome) {
				event.preventDefault();
				_this.showHideMobileNav({hide: true});
			}

			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
				var target = $(this.hash);

				_this.scrollTo({
					pos: target.offset().top
				});
			}
		});

		$('.section-offer-nav li a').click(function(event) {
			event.preventDefault();

			var index = $(this).parent().index();
			
			_this.offerIndex = index;
			_this.setOfferItem(_this.offerIndex);
			clearInterval(_this.offerInterval);
			_this.startOfferInterval();
		});

		$(window).on('resize', function(event) {
			event.preventDefault();
			_this.onResize();
		});

		$(window).on('scroll', function(event) {
			_this.onScroll();
		});
	},

	initLazyLoad: function() {
		let images = document.querySelectorAll(".lazyload");
		new LazyLoad(images);
	},

	setNavURL: function() {
		$('.menu li a').each(function(index, el) {
			var href = $(el).attr('href');
			
			if (href.substring(0, 1) == '#') {
				$(el).attr('href', '/'+href);
			}
		});
	},

	initPhotoswipe: function(index) {
		var pswpElement = document.querySelectorAll('.pswp')[0];
		var items = [];

		$('.page-offer-gallery img').each(function(item) {
			items.push({
				src: $(this).attr('src'),
				w: 0,
				h: 0
			})
		});
		
		var options = {
			index: index ? index : 0 // start at first slide
		};
		
		// Initializes and opens PhotoSwipe
		var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

		gallery.listen('gettingData', function(index, item) {
			if (item.w < 1 || item.h < 1) { // unknown size
				
				var img = new Image();

				img.onload = function() { // will get size after load
					item.w = this.width; // set image width
					item.h = this.height; // set image height
					gallery.invalidateCurrItems(); // reinit Items
					gallery.updateSize(true); // reinit Items
				}
				img.src = item.src; // let's download image
			}
		});

		gallery.init();
	},

	startOfferInterval: function() {
		var _this = this;

		if (this.isHome) {
			_this.offerInterval = setInterval(function() {
				if (_this.offerIndex < $('.section-offer-nav li').length) {
					_this.setOfferItem(_this.offerIndex);
					_this.offerIndex++;
				} else {
					_this.setOfferItem(0);
					_this.offerIndex = 1;
				}
			}, 3000)
		}
	},

	setOfferItem(index) {
		var _this = this;

		$elements = $('.section-offer-nav li, .section-offer-preview li')
		$elements.removeClass('active')

		_this.setOfferHeight();

		$('.section-offer-nav li').eq(index).addClass('active')
		$('.section-offer-preview li').eq(index).addClass('active')
	},

	onResize: function() {
		app.W = $(window).width()
		app.H = $(window).height()

		app.setOfferHeight();

		if (app.W > 1200 &&  $('header').hasClass('active')) {
			$('header').removeClass('active')
		}
	},

	onScroll: function() {
		app.scrollTop = $(window).scrollTop()

		if (app.scrollTop > 200) {
			$('.header-logo').addClass('compact')
		} else {
			$('.header-logo').removeClass('compact')
		}

	    if (app.isHome) {
	    	this.homeSectionWatcher();
	    }
	},

	homeSectionWatcher: function() {
		$('[data-watch]').each(function(index, el) {
			var _thisOffsetTop = $(el).offset().top;

			if (app.scrollTop > _thisOffsetTop - 120 && app.scrollTop < _thisOffsetTop + $(el).height() + (app.H/2)) {
				$('.menu li').eq(index).addClass('active')
			} else {
				$('.menu li').eq(index).removeClass('active')
			}
		});
	},

	setOfferHeight: function() {
		var $offerPreview = $('.section-offer-preview')

		if (app.isHome) {
			$offerPreview.css('min-height', $offerPreview.find('li').first().height());
		}
	},

	scrollToTop: function() {
		if ($(window).scrollTop() > 0) {
			this.scrollTo({
				pos: 0, 
				speed: 0.4
			});
		}
	},

	scrollTo: function(params) {

		if (params.pos == undefined) {params.pos = 0};
		if (params.speed == undefined) {params.speed = 1};

		TweenLite.to( window, params.speed, {
			scrollTo: {
				y: params.pos
			},
			ease: Cubic.EaseIn, 
			onComplete: function(){
				if (params.after) {
					params.after();
				}
			}
		});
	},

	showHideMobileNav: function(param) {
		var $header = $('header'),
			$nav = $('.header-mobileNav-trigger'),
			param = param || {};
			// $pageContent = $('.page-content');

		if (app.isMobileNavOpen || param.hide == true) {
			$header.removeClass('active');
			$nav.removeClass('active');
			app.isMobileNavOpen = false
		} else {
			$header.addClass('active');
			// $pageContent.addClass('mask');
			$nav.addClass('active');
			app.isMobileNavOpen = true
		}
	},

	preload: function(images) {

		var $body = $('body'),
			_this = this;

		if (images) {
			$.each(images, function(index, val) {
				$body.find('.footer').append('<img class="preloading" src=" ' + val +' ">');
			});
		}

		imagesLoaded( 'body', { background: true }, function() {
			$body.find('.preloading').each(function(index, el) {
				$(this).remove();
			});

			setTimeout(function() {
				$body.removeClass('loading');
			}, 400);

			_this.sliderInstance = new _this.slider( $('.slider') );
			_this.sliderInstance.init();
		});
	},

	slider: function($slider) {
		var _this = this,
			sliderIndex = 0,
			sliderMax = $slider.find('.slider-holder .slider-box').length,
			pxToMove = $slider.find('.slider-holder-outer').width(),
			isMoved = false,
			interval,
			previousActive;

		function setupEvents() {
			
			var mc = new Hammer($slider.get(0));

			mc.on("swipeleft", function(ev) {
				moveRight();
			});

			mc.on("swiperight", function(ev) {
				moveLeft();
			});

			$slider.on( "swipeleft",  function(event){
				moveLeft();
			});

			$slider.on( "swiperight",  function(event){
				moveRight();
			});

			$slider.on('click', '.counter-move-left', function(event) {
				event.preventDefault();
				moveLeft();
			});

			$slider.on('click', '.counter-move-right', function(event) {
				event.preventDefault();
				moveRight();
			});

			$slider.on('parentResized', function(event) {
				event.preventDefault();
				pxToMove = $slider.find('.slider-holder-outer').width();

				setSliderBoxWidth();
				move(0);
			});

			$(window).on('resize', function(event) {
				$slider.trigger('parentResized');
			});
		}

		function setSliderBoxWidth() {
			var parentWidth = $slider.find('.slider-holder-outer').width();

			$.each($slider.find('.slider-box'), function(index, val) {
				 $(val).css('width', parentWidth + "px");
			});
		}

		function move(thisIndex) {
			$slider.find('.slider-holder').each(function(index, el) {
				var px;

				if (!isNaN(thisIndex)) {

					sliderIndex = thisIndex;

					$('.slider-count .counter').html(sliderIndex+1);

					$('body').find('.slider-nav li').removeClass('active');
					$('body').find('.slider-nav li:nth-child('+(sliderIndex+1)+')').addClass('active');

					previousActive = $('body').find('.slider-holder li.active');

					$('body').find('.slider-holder li').removeClass('fadeOut');
					previousActive.removeClass('active').addClass('fadeOut');
					$('body').find('.slider-holder li:nth-child('+(sliderIndex+1)+')').addClass('active');
				};
			});
			
			clearSliderInterval();
			startSliderInterval();
		}

		function moveLeft() {
			if (sliderIndex > 0) {
				sliderIndex--;
				move(sliderIndex);
			} else if (sliderIndex < 1) {
				sliderIndex = sliderMax - 1;
				move(sliderIndex)
			}
		}

		function moveRight() {
			if (sliderIndex < sliderMax - 1) {
				sliderIndex++;
				move(sliderIndex);
			} else if (sliderIndex == sliderMax - 1) {
				sliderIndex = 0;
				move(0);
			}
		}

		function undelegateEvents()  {
			$slider.unbind('parentResized');
		}

		function startSliderInterval() {
			interval = setInterval(function() {
				moveRight();
			}, 4000);
		}

		function clearSliderInterval() {
			clearInterval(interval);
		}

		function init() {
			setupEvents();
			setSliderBoxWidth();
			
			startSliderInterval();

			setTimeout(function() {
				$slider.find('.slider-holder .slider-box').first().addClass('active');
			}, 50);
		}

		return {
			init: init,
			undelegateEvents: undelegateEvents,
			move: move,
			moveRight: moveRight
		}
	}
};

$(document).ready(function() {
	window.app.init();
});